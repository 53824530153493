body {
  color: #fff;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}
* {
  box-sizing: border-box;
  line-height: 1;
}
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 0;
  width: 100%;
  height: 100vh;
}
.top {
  height: 20%;
  min-height: 25%;
  max-height: 25%;
  width: 100%;
  background-color: #333;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
.top .image {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: auto;
  transition: opacity .5s ease;
  height: 25vh;
}
.info {
  background-color: #fff;
  padding: 2rem 2.5rem;
  font-size: 100px;
  color: #333;
  position: absolute;
  top: 100px;
  left: 100px;
  margin: 0;
  text-transform: uppercase;
}
.text {
  position: absolute;
  width: 100%;
  top: 350px;
  left: 100px;
  color: #fff;
  font-size: 80px;
  margin: 0;
}

.box {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
}

.bottom {
  width: 100%;
  flex-grow: 1;
  background-color: rgb(226, 226, 226);
  padding: 50px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 3rem;
}
.bottom .meals {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 5rem;
}
.bottom .header {
  background-color: #333;
  font-size: 80px;
  padding: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 0;
}
.meal {
  width: 100%;
  color: #333;
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  flex-direction: row;
}
.meal .tag {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
  width: 300px;
  font-size: 140px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.meal .tag.small {
  font-size: 70px;
  width: 170px;
}
.meal .content {
  padding: 50px;
  flex-grow: 1;
  overflow: hidden;
}
.meal .content.small {
  padding: 30px;
}
.meal .content .title {
  margin: 0;
  font-size: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.meal .content .description {
  font-size: 45px;
  margin: 0;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.meal .content .description.large {
  margin-top: 0.5rem;
}
.meal .content .allergens {
  margin: 0;
  font-size: 40px;
  font-style: italic;
}
.meal .price {
  background-color: #333;
  padding: 50px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: bold;
  min-width: 370px;
  flex-basis: 370px;
  width: 370px;
  max-width: 370px;
  color: #fff;
}
.meal .price.small {
  padding: 30px;
  min-width: 270px;
  width: 270px;
  flex-basis: 270px;
}

#logo {
  bottom: 100px;
  left: 100px;
  height: 160px;
  position: absolute;
  z-index: 10;
}
#basilikum {
  bottom: -450px;
  right: -300px;
  width: 800px;
  height: 800px;
  transform: rotate(-10deg);
  position: absolute;
  z-index: 10;
}
#bowls {
  bottom: -420px;
  right: 200px;
  width: 500px;
  height: 550px;
  position: absolute;
  z-index: 9;
}
#datetime {
  position: absolute;
  bottom: 50px;
  left: 50px;
  font-size: 50px;
  color: #333;
  font-weight: bold;
}

.mealsWrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 4rem;
  will-change: opacity;
  transition: all 0.2s ease;
}
.mealsWrapper .meals {
  flex: 1 1 50%;
}
.mealsWrapper .meals.large {
  width: 100%;
}
.mealsWrapper.twoRows {
  gap: 4rem;
}
.mealsWrapper.twoRows .meals {
  flex-grow: 1;
  gap: 3rem;
}
.mealsWrapper.twoRows .meals .meal .tag {
  font-size: 65px;
  width: 120px;
}
.mealsWrapper.twoRows .meals .meal .price {
  width: 200px;
  flex-basis: 200px;
  min-width: 200px;
  max-width: 200px;
  font-size: 45px;
}
.mealsWrapper.twoRows .meals .meal .price.large {
  background-color: transparent;
  padding: 0;
  margin: 1rem 0 0 0;
  color: #333 !important;
  width: auto;
  max-width: unset;
  flex-basis: unset;
  min-width: unset;
  text-align: left;
  justify-content: unset;
}
.mealsWrapper.twoRows .meals .meal .content {
  padding: 30px 20px;
}
.mealsWrapper.twoRows .meals .meal .content .title {
  font-size: 55px;
}
